import React from 'react'
import styled from 'styled-components'
export default function Footer({isMobile}) {
  return (
    <div style={{width:'100%',textAlign:'center'}}>
        <FooterDes $isMobile={isMobile}>© 2024 BIZPAY INC. All rights reserved.</FooterDes>
    </div>
  )
}


const FooterDes = styled.p`
    font-weight: 400;
    font-size:  ${props => props.$isMobile ? "12px" : "18px"} ;
    color: #FFFFFF;
    line-height:${props => props.$isMobile ? "17px" : "28px"} ;
    padding:${props => props.$isMobile ? "10px" : "18px"} ;
    margin: 0;
`