import React, { useState } from 'react'
import styled from 'styled-components'
import toast from 'react-hot-toast';
import { feedbackInfo } from '../../service/service';
import { isEmail } from '../../utils/utils';

export default function Feedback({isMobile}) {
 const [content,setContent] = useState('');
 const [email,setEmail] = useState('');
 const EmailNumber = 'business@prismai.cloud'
 const sendINfo = () => {
    if(!content || !email){
        toast.error('Please enter in full !')
        return false;
    }
    if(!isEmail(email)){
        toast.error('Please enter the correct email format !')
        return false;
    }
    
    feedbackInfo({
        content:content,
        contact:email
    }).then(res => {
       
        if(res.data.code === 0){
            toast.success('Thank you for your feedback !',{
                style: {
                    border: '1px solid #9440FF',
                    padding: '16px',
                    color: '#FFFAEE',
                    background: '#9440FF',
                  },
                  iconTheme: {
                    primary: 'green',
                    secondary: '#FFFAEE',
                  },
            });
            setTimeout(() => {
                window.location.reload();
            }, 600);
            // setEmail('')
            // setContent('')
        }
    })
 }
 const Copyfn = () => {
        var aux = document.createElement("input");
        aux.setAttribute("value", EmailNumber);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        toast.success('Copied to clipboard!',{
            style: {
                border: '1px solid #9440FF',
                padding: '16px',
                color: '#FFFAEE',
                background: '#9440FF',
              },
              iconTheme: {
                primary: 'green',
                secondary: '#FFFAEE',
              },
      });
 }
  return (
        <Content $isMobile={isMobile}>
             <h3 id='support'>SUPPORT</h3>
            <FlexBox $isMobile={isMobile}>
                <FeedbackBlock $isMobile={isMobile}>
                    <FeedbackForm $isMobile={isMobile}>
                        <div  className='title'>Feedback</div>
                        <p className='des'>In order to better improve our products, we listen to your opinions.</p>
                        <div className='input-group'>
                            <label>Email</label>
                            <div className='input-block'>
                                <Input  value={email} onChange={e => setEmail(e.target.value)}  />
                            </div>
                        </div>
                        <div className='input-group'>
                            <label>Content</label>
                            <div className='textarea-block'>
                                <TextArea  rows={5} value={content} onChange={e => setContent(e.target.value)}  />
                            </div>
                        </div>
                        <div className='btn'>
                            <Button onClick={sendINfo}>Submit</Button>
                        </div>
                    </FeedbackForm>
                </FeedbackBlock>
                <ContactUsBlock $isMobile={isMobile}>
                    <h3>CONTACT US</h3>
                    <p className='des'>Please click to copy the email address and send an email to contact customer service.</p>
                        <Email onClick={Copyfn}>
                            <img  className='icon' src={require('../../assets/youxiang.png')} alt='icon' />
                            <div className='email-text'>{EmailNumber}</div>
                        </Email>
                </ContactUsBlock>
            </FlexBox>
        </Content>
  )
}

const Content = styled.div`
  
  margin-bottom:84px;
  padding:${props => props.$isMobile ? '0 15px': '0'} ;
  h3{
        font-weight: 600;
        font-size: 16px;
        color: #9440FF;
        line-height: 22px;
        text-align: left;
        font-style: normal;
    }
  /* justify-content: space-between; */
  /* align-items: center; */
`
const FlexBox = styled.div`
    /* width: 100%; */
   display: flex;
   flex-direction:${props => props.$isMobile ? 'column': 'row'} ;
`

const FeedbackBlock = styled.div`
    width:${props => props.$isMobile ? '100%': '50%'} ;
`
const FeedbackForm = styled.div`
    width:${props => props.$isMobile ? 'auto': '588px'};
    box-sizing:border-box;
    min-height: 486px;
    padding: ${props => props.$isMobile ? '15px': '18px 33px'};
    background: #121116;
    border-radius: 10px;
    border: 1px solid #000000;
    .title{
        font-weight: 600;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 45px;
        text-align: left;
        font-style: normal;
    }
    .des{
        font-weight: 400;
        font-size: 16px;
        color: rgba(255,255,255,0.6);
        line-height: 24px;
        text-align: left;
        font-style: normal;
        margin-top:13px;
        margin-bottom:13px;
    }
    .input-group{
        margin-top: 20px;
        label{
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
        .input-block{
            margin-top: 12px;
            padding:5px 10px;
            background: #211F2A;
            border-radius: 10px;
            border: 1px solid #000000;
        }
        .textarea-block{
            margin-top: 12px;
            padding:5px 10px;
            background: #211F2A;
            border-radius: 10px;
            border: 1px solid #000000;
        }
       
    }
    .btn{
            text-align: center;
            margin-top: 46px;
           
     }

`

const Input = styled.input`
    width: 100%;
    height: 45px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background:transparent;
    border: none;
    outline: none;
    display: block;
`
const TextArea = styled.textarea`
    max-width: 100%;
    width: 100%;
    min-height: 100px;
    max-height: 135px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background:transparent;
    border: none;
    outline: none;
    display: block;

`
const Button = styled.button`
    width: 240px;
    height: 45px;
    background: #121116;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    &:hover{
        color: #9440FF;
        border: 1px solid #9440FF;
    }
`
const ContactUsBlock = styled.div`
  width:${props => props.$isMobile ? 'auto': '50%'} ;
  padding:${props => props.$isMobile ? '15px': '18px 15px'};
  margin-left:${props => props.$isMobile ? '0': '45px'};
  margin-top:${props => props.$isMobile ? '25px': '0'};
  h3{
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: left;
    font-style: normal;
  }
  .des{
    width:${props => props.$isMobile ? '100%': '396px'};;
    height: 48px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255,255,255,0.6);
    line-height: 24px;
    text-align: left;
    font-style: normal;
    margin: 20px 0;
  }

`
const Email = styled.div`
    display: flex;
    align-items: center;
    .icon{
        width: 24px;
        height: 24px;
        margin-right: 15px;
    }
    .email-text{
        font-weight: 400;
        font-size: 21px;
        color: #FFFFFF;
        line-height: 29px;
        text-align: left;
        font-style: normal;
        cursor: pointer;
    }
   &:hover{
       .email-text{
           color: #9440FF;
       }
   }
`

