import React from 'react'
import styled from 'styled-components'
export default function Header({isMobile}) {
  return (
    <HeaderBlock $isMobile ={isMobile}>
        <Image  src={require("../assets/logo@2x.png")} alt='logo'  />
    </HeaderBlock>
  )
}


const HeaderBlock = styled.div`
    /* width: 100%; */
    background-color: transparent;
    padding:${props => props.$isMobile ? "18px 15px" : "43px 100px"} ;

`
const Image = styled.img`
    width: 142px;
    height: 42px;
`