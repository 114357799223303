import React from "react";
import AppStateContext from "./context/app-state-context";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import TOS from "./pages/tos";
import Privacy from "./pages/privacy";
import ThemeContext from "./context/theme-context";
import Home from "./pages/home";
import Layout from "./components/layout";
import { Toaster } from 'react-hot-toast';
function App() {
    return (
        <BrowserRouter>
            <AppStateContext>
                <ThemeContext>
                    <Layout>
                        <Routes>
                            <Route path={'/'} element={<Home/>}/>
                            <Route path={'/tos'} element={<TOS/>}/>
                            <Route path={'/privacy'} element={<Privacy/>}/>
                        </Routes>
                        <Toaster/>
                    </Layout>
                </ThemeContext>
            </AppStateContext>
        </BrowserRouter>
    );
}

export default App;
