import request from './request'

//post 请求数据方式
export function feedbackInfo (data) {
    return request('/feedback/save', {
        method: 'POST',
        data
    })
}

//get 请求数据方式
export function getDemo (params) {
    return request('/user', {
        method: 'GET',
        params
    })
}
export function postDemo (data) {
    return request('/v1/shop/search', {
        method: 'POST',
        data
    })
}
