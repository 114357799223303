import React from 'react'
import styled from 'styled-components'

export default function TOS() {
  return (
    <Content className='container privacy-policy'>
      <div className='content-wrapper' style={{paddingTop:'1rem'}}>
        
        <h1>Terms of Service</h1>
        <p>
          If your usual residence is outside of the European Economic Area, the
          United Kingdom or Switzerland
        </p>
        <p>
          <strong>Please read carefully:</strong> These Terms of Service (these
          “<strong>Terms</strong>”) constitute a legally binding agreement
          between you (“<strong>you</strong>” or “<strong>your</strong>”) and
          Prism.(collectively with its successors
          in interest and permitted assigns, “Prism,” “we,” “us” or “our”).
        </p>
        <p>
          These Terms govern your access and use of our websites where these
          Terms are posted (the “<strong>Sites</strong>”), our mobile
          applications (the “<strong>Apps</strong>”), and our related content,
          products, and services (collectively, and together with the Sites and
          the Apps, the “<strong>Service</strong>”).
        </p>
        <p>
          <strong>
            THESE TERMS CONTAIN VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS
            AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, DISCLAIMERS OF
            WARRANTIES, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. THESE TERMS ALSO
            CONTAIN PROVISIONS REGARDING THE AUTOMATIC RENEWING OF YOUR
            SUBSCRIPTION. THESE TERMS REQUIRE THE USE OF ARBITRATION TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. PLEASE READ THEM
            CAREFULLY.
          </strong>
        </p>
        <p>
          <strong>
            THESE TERMS CONTAIN A BINDING, INDIVIDUAL ARBITRATION REQUIREMENT
            AND CLASS-ACTION WAIVER, WHICH MEANS YOU AND PRISM AGREE TO RESOLVE
            MOST DISPUTES IN BINDING, INDIVIDUAL ARBITRATION AND NOT BY MEANS OF
            A CLASS ARBITRATION, A CLASS ACTION, ANY OTHER KIND OF
            REPRESENTATIVE PROCEEDING, OR A JURY TRIAL. (SEE SECTION 19 (DISPUTE
            RESOLUTION; BINDING INDIVIDUAL ARBITRATION).) YOU MAY OPT OUT OF THE
            ARBITRATION REQUIREMENT WITHIN 30 DAYS OF ACCEPTING THESE TERMS;
            INSTRUCTIONS FOR OPTING OUT ARE IN SECTION 19 (DISPUTE RESOLUTION;
            BINDING INDIVIDUAL ARBITRATION) BELOW.
          </strong>
        </p>
        <p>
          By downloading, installing or using the Service in any manner, you
          agree to these Terms and you agree to comply with and be bound by any
          applicable specific, supplemental or third-party licenses or terms
          when using the Service.
        </p>
        <p>
          <strong>
            IF YOU DO NOT AGREE TO ALL TERMS AND CONDITIONS IN THESE TERMS, DO
            NOT ACCESS AND IMMEDIATELY STOP USING THE SERVICE, CANCEL ANY
            ACCOUNT, AND IMMEDIATELY UNINSTALL AND DELETE ANY COPIES OF ANY APPS
            OR SOFTWARE INCLUDED IN THE SERVICE IN YOUR POSSESSION.
          </strong>
        </p>
        <p>
          These Terms were originally drafted in English. If there is any
          conflict between the English-language version of these Terms and a
          version translated into another language, the English-language version
          will prevail.
        </p>
        <p>
          If you have questions or complaints with respect to these Terms or our
          Service, visit the relevant support page:
        </p>
        <ul>
          <li>
            <a href='/#support'><strong><i>Prism Support</i></strong></a>
          </li>
          <li>
            <p>Or send us an email at <strong><i>business@prismai.cloud</i></strong></p>
          </li>
        </ul>
        <ol type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-text-opacity))'
              }}>
              <strong>Changes to the Terms and the Service</strong>
            </p>
          </li>
        </ol>
        <p>
          We may update these Terms from time to time, in our sole discretion
          (for example, when we remove or release new features, technologies, or
          services, to comply with legal, regulatory, or contractual
          requirements, or in response to exceptional or unforeseen
          circumstances). In such cases, we will take reasonable measures to
          inform you, in accordance with the significance of the changes
          performed, for example, by revising the date at the top of the updated
          Terms, via email or as a pop-up or push notification within the
          Service. It is your responsibility to review the Terms regularly, and
          to check the Service for updates to these Terms regularly. Unless
          otherwise noted, the updated Terms will be effective as of the time at
          which we post the updated Terms in the Service. By continuing to
          access or use our Service after updates become effective, you agree to
          be bound by the updated Terms. If you do not agree to the updated
          Terms, you must immediately stop using our Service, uninstall and
          delete any copies of any Apps or any software included in the Service
          in your possession, and cancel your subscription and any account.
        </p>
        <p>
          We may also update, change, suspend or discontinue the Service (or any
          part, content or feature) at any time, without notice and without
          liability to you or anyone else (for example, to offer or test new or
          different features, technologies, or services, to repair, improve or
          further develop the Service, to comply with legal, regulatory or
          contractual requirements, or in response to exceptional or unforeseen
          circumstances).
        </p>
        <p>
          Some services and features may not be available in all countries, in
          all languages, or in all operating systems.
        </p>
        <ol start='2' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>Privacy</strong>
            </p>
          </li>
        </ol>
        <p>
          Please refer to the applicable privacy policy for information on how
          we process your personal data, treat your information and protect your
          privacy when you use the Service:
        </p>
        <ul>
          <li>
            <a href='/privacy' target='_blank'>
            <strong><i> Prism Privacy Policy</i></strong>
            </a>
          </li>
        </ul>
        <ol start='3' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>Eligibility</strong>
            </p>
          </li>
        </ol>
        <p>
          <strong>
            OUR SERVICE IS NOT AVAILABLE TO INDIVIDUALS UNDER THE AGE OF 16.
          </strong>{' '}
          If you are over the age of 16 but under the legal age of majority in
          your state of residence, your parent or guardian must review and agree
          to be bound by these Terms on your behalf and must supervise your use
          of the Service.
        </p>
        <ol start='4' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>End User License</strong>
            </p>
          </li>
        </ol>
        <p>
          Subject to your compliance with these Terms and your payment of the
          applicable subscription fees, we hereby grant you, for your personal
          and non-commercial purposes, a limited, revocable, non-transferable,
          non-sublicensable, non-exclusive license to use the Service and to
          download and install a copy of the applicable App provided to you by
          us or on our behalf on a permitted device that you own or control.
        </p>
        <p>
          Except as expressly permitted by these Terms and the terms applicable
          to the mobile application store where we make the applicable App
          available (each, a “<strong>App Marketplace</strong>”), you cannot:
          (a) rent, lease, lend, sell, distribute, sublicense, or otherwise
          transfer or make available the Service; or (b) copy, decompile,
          reverse engineer, disassemble, attempt to derive the source code of,
          modify, alter, mimic, adapt, translate, or create derivative works of
          the Service, any updates, content or any part thereof (except as and
          only to the extent any foregoing restriction is prohibited by
          applicable law or to the extent as may be permitted by the licensing
          terms governing use of any open source components included with the
          Service).
        </p>
        <ol start='5' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Purchases, Subscriptions, Promotional Codes, and Auto Renewals
              </strong>
            </p>
          </li>
        </ol>
        <p>
          We may offer free trials in our sole discretion. Upon installation or
          expiration of a free trial offer (if applicable), or a redeemed
          Virtual Item or Promotional Code, you will be charged the applicable
          subscription fees (if any) for your use of the Service.
          <strong>
            {' '}
            Upon expiration of the initial term of your subscription, whether
            such subscription is weekly, monthly, quarterly, yearly, or
            otherwise, your subscription will automatically renew for recurring
            subscription periods of the same duration unless and until you
            affirmatively cancel your subscription. If you have purchased your
            subscription through an App, you can cancel your subscription or
            automatic renewals any time in your account settings with the App
            Marketplace according to the policies of each App Marketplace. If
            you have purchased your subscription through the Site, you can
            manage and cancel your subscription and any automatic renewals at
            any time following the instructions in your subscription purchase or
            renewal confirmation emails, or by following the instructions in the
            applicable support page of the Service.
          </strong>{' '}
          Uninstalling an App will not result in the cancellation of your
          subscription. If you have begun a subscription period and you then
          decide to cancel your subscription during such period, you will not
          receive a refund for the fees you already paid for such subscription
          period, and you will continue to be able to use the Service for which
          you subscribed until the end of such subscription period. We may
          change the applicable subscription fees at any time at our sole
          discretion. Changes to the subscription fees will not apply
          retroactively.
        </p>
        <p>
          The Service may enable you to purchase a limited, personal,
          non-transferable, non-sublicensable, revocable license to use virtual
          items or in-app consumables (collectively, the “
          <strong>Virtual Items</strong>”), which could include virtual cards,
          tokens or other units that can be redeemed for additional features,
          enhancements, functionalities, content, or services within the
          Service. You are only allowed to purchase Virtual Items through the
          Service and you may only redeem or use Virtual Items through the
          Service in which the Virtual Items are purchased. We may manage,
          regulate, change, or remove Virtual Items at any time at our sole
          discretion.{' '}
          <strong>
            All consumable in-app purchases made through the Service and all
            Virtual Items (and all redemptions of Virtual Items) are final and
            non-refundable. You acknowledge that you will not receive any refund
            or compensation for unused Virtual Items when your access to the
            Service or your account (if any) is suspended or terminated for any
            reason.
          </strong>
        </p>
        <p>
          We may provide you with gift cards or promotional codes that can be
          redeemed for additional features, enhancements, functionalities,
          content, services, or Virtual Items within a specified Service and for
          a limited period of time, subject eligibility requirements (the “
          <strong>Promotional Codes</strong>”). Promotional Codes have no cash
          value, are personal, non-transferable, non-sublicensable, and we are
          under no obligation to provide any compensation in connection with a
          Promotional Code. The Service may require that you create or log in to
          your account to use Promotional Codes.
        </p>
        <p>
          The validity of these Terms and the validity of any purchase
          associated with the Service will be considered independently from each
          other, meaning that the termination of such associated purchase will
          not affect the validity of these Terms, and the termination of these
          Terms may not affect the validity of such associated purchase.
        </p>
        <ol start='6' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>Prohibited Uses</strong>
            </p>
          </li>
        </ol>
          <p>
            You agree that you will not, and will not permit any person accessing
            the Service using your account or device to do any of the following:
          </p>
        <ul>
          <li>
            <p>Use the Service in any manner not permitted by these Terms;</p>
          </li>
          <li>
            <p>Use the Service for any purposes prohibited by applicable laws or regulations, or in any manner that violates or infringes upon the rights of others;</p>
          </li>
          <li>
            <p>Import, submit, upload, publish, post, communicate, or transmit to others in any way whatsoever, any unlawful, fraudulent, deceptive, harmful, defamatory, inaccurate, abusive, offensive, threatening, hateful, violent, harassing, discriminatory or racist content, or any content that infringes or violates another person’s rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity);</p>
          </li>
          <li>
            <p>Copy (except as expressly permitted by these Terms) or modify the Service;</p>
          </li>
          <li>
            <p>Frame, mirror, display or incorporate the Service or any portion into any other program, site, service or product;</p>
          </li>
          <li>
            <p>Use the Service in a manner that interferes with, degrades, or disrupts the integrity or performance of any of our networks, technologies, products or services;</p>
          </li>
          <li>
            <p>Use any data mining or similar automated or manual data extraction, gathering or scraping methods in connection with the Service;</p>
          </li>
          <li>
            <p>Circumvent, bypass, defeat, modify, tamper or disable any content protection system, digital rights management, security feature or functionality in the Service; or</p>
          </li>
          <li>
            <p>Allow others to do any of the foregoing.</p>
          </li>
        </ul>
        <ol start='7' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Our Intellectual Property Rights
              </strong>
            </p>
          </li>
        </ol>
        <p>We or our licensors retain and exclusively own all rights, title and interest in and to the Service and its content (including software, artwork, photos, videos, music, sounds, text, information and other materials posted, provided or otherwise made available through the Service, but excluding any User-Generated Content), including all intellectual proprietary rights, whether registered or not, which include, but are not limited to, copyrights, patents, patent disclosures and inventions (whether patentable or not), trademarks, service marks, trade secrets, know-how and other confidential information, trade dress, trade names, logos, corporate names and domain names, together with all of the goodwill associated therewith, derivative works and all other rights in and to the Service.</p>
        <p>We reserve all rights not expressly granted to you under these Terms.</p>
        <ol start='8' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                User-Generated Content
              </strong>
            </p>
          </li>
        </ol>
        <p>The Service or one or more Promotions (as defined below) may provide features that allow you to upload, store, receive, create, modify, share or publish textual, visual, audio or other content (collectively, the “<strong>User-Generated Content</strong>”).</p>
        <p>We do not claim any ownership rights to the User-Generated Content.</p>
        <p>In order to allow us to operate, provide you with, and improve the Service and our technologies (and to develop new ones), we must obtain from you certain rights related to User-Generated Content that is covered by intellectual property rights. You hereby grant us a license to use User-Generated Content for the purposes of operating, developing, and improving the Service or new technologies or services, except that we will only use your User-Generated Content to improve the performance of machine learning models with your prior consent, all in accordance with the applicable privacy policy (please refer to section 2 (<strong>Privacy</strong>) for the applicable privacy policy). You will not be entitled to compensation for any use of User-Generated Content by us under these Terms.</p>
        <p>You are solely responsible for the User-Generated Content, and you represent and warrant that such content will not infringe upon or violate the rights of any person, that it complies with these Terms and any applicable law, and that you have all the rights and authorizations necessary to grant the license in the paragraph above and to upload, store, receive, create, modify, share or publish the User-Generated Content on or through the Service and, when applicable, in connection with the Promotion.</p>
        <p>We welcome feedback, comments, and suggestions for improvements to the Service (“<strong>Feedback</strong>”). If you provide any Feedback, we may use it without restriction or compensation to you.</p>
        <ol start='9' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Generative AI Features
              </strong>
            </p>
          </li>
        </ol>
        <p>The Service may enable you to use certain features that implement one or more generative artificial intelligence models or tools (the “<strong>Generative AI Features</strong>”). The Generative AI Features may allow you to insert or upload as input text, images, video, audio or other content (“<strong>Input</strong>”). Based on the Input, you may generate and receive as output content such as text, images, video, or audio (“<strong>Output</strong>”). Your Input and Output are considered User-Generated Content under these Terms and, as between you and us, your Input and Output are yours.</p>
        <p>Without limiting section 6 (<strong>Prohibited Uses</strong>), when using Generative AI Features, you will not, and will not permit any person accessing the Service using your account to do any of the following:</p>
        <ul>
          <li><p>Upload, generate, or distribute content that facilitates the exploitation or abuse of children, including all child sexual abuse materials and any portrayal of children that could result in their sexual exploitation;</p></li>
          <li><p>Upload, generate, or distribute content that contains or promotes sexually explicit content or profanity, including pornography, or any content or services intended to be sexually gratifying;</p></li>
          <li><p>Perform or facilitate dangerous, illegal, or malicious activities, including (a) facilitation or promotion of illegal activities or violations of law, (b) abuse, harm, interference, or disruption of services (such as spam, phishing, or malware), (c) attempts to override or circumvent safety filters or intentionally drive the Generative AI Features to act in a manner that contravenes these Terms or other applicable policies, (d) generation or distribution of content that may harm or discriminate against individuals or a group, or promote any of the foregoing, and (e) generation or distribution of content intended to misinform, misrepresent, mislead, exploit, or harm others;</p></li>
          <li><p>Upload, generate, or distribute content that encourages the illegal or inappropriate use of alcohol, tobacco, drugs, or the use of explosives, firearms, ammunition or certain firearms accessories;</p></li>
          <li><p>Upload, generate, or distribute content that promotes violence or incite hatred against individuals or groups based on race or ethnic origin, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity, caste, immigration status or any other characteristic that is associated with systemic discrimination or marginalization; or</p></li>
          <li><p>Upload, generate, or distribute content that depicts gratuitous violence or other dangerous activities.</p></li>
        </ul>
        <ol start='10' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Promotions, Contests and Sweepstakes
              </strong>
            </p>
          </li>
        </ol>
        <p>Promotions, contests, and sweepstakes that you enter on the Service or in connection with the Service (each, a “<strong>Promotion</strong>”) may be subject to official rules or conditions that are supplemental to these Terms, and which may provide eligibility requirements, entry instructions, deadlines, prize information and restrictions. If you wish to participate in a Promotion, please refer to the applicable official rules or conditions. If a Promotion’s official rules or conditions conflict with these Terms, the provisions contained in the official rules or conditions govern and control the relevant Promotion. Your entry into a Promotion constitutes User-Generated Content and is subject to all provisions of these Terms that govern your submission and our use of your User-Generated Content, and we may also use such User-Generated Content for advertising, marketing, and promotional purposes.</p>
        <ol start='11' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Third-Party Services, Materials and Advertising
              </strong>
            </p>
          </li>
        </ol>
        <p>The Service may enable access to third-party services, software, and websites (collectively, “<strong>Third-Party Services</strong>”). Service may also display, include or make available content, information, and materials from third parties, including User-Generated Content (collectively, “<strong>Third-Party Materials</strong>”) or provide links to certain Third-Party Services or Third-Party Materials.</p>
        <p>You understand that by using the Service, you may encounter content, including Third-Party Services and Third-Party Materials, that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search may automatically and unintentionally generate links or references to objectionable material. By using the Service, you further acknowledge and agree that we are not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third-Party Services or Third-Party Materials. We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any Third-Party Services or Third-Party Materials. Such Third-Party Services and Third-Party Materials are provided solely as a convenience to you. You agree to use the Service and any Third-Party Services or Third-Party Materials at your sole risk, and you acknowledge that we will not have any liability to you for content that may be found to be offensive, indecent, or objectionable.</p>
        <p>In addition, Third-Party Services and Third-Party Materials that may be accessed from, displayed on or linked from your device may not be available in all languages, in all countries, or in all operating systems. We make no representation that such Third-Party Services and Third-Party Materials are appropriate or available for use in any particular location. To the extent you choose to access or use such Third-Party Services or Third-Party Materials, you do so at your own initiative and are responsible for compliance with any applicable laws.</p>
        <p>We, and our content providers and licensors, reserve the right to change, suspend, remove, limit, or disable access to any Third-Party Services or Third-Party Materials at any time without notice. We will not be liable for the limitation, removal of, or disabling of access to any such services or materials.</p>
        <p>You acknowledge and agree to comply with applicable third-party terms and conditions related to the use of the Service, Third-Party Services, and Third-Party Materials.</p>
        <p>The Service may contain advertisements. In consideration for your access and use of the Service, you agree that we, our affiliates, and our third-party partners may place advertising on the Service.</p>
        <ol start='12' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Disclaimer of Warranties
              </strong>
            </p>
          </li>
        </ol>
        <p><strong>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE (EXPRESSLY INCLUDING ALL CONTENT AND FEATURES MADE AVAILABLE VIA THE SERVICE), IS PROVIDED "AS IS" AND “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE SERVICE, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF COURSE OF DEALING, OF TRADE USAGE OR PRACTICE, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NON-INFRINGEMENT. WE DO NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE SERVICE, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE SERVICE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SERVICE WILL BE CONSTANTLY AVAILABLE, UNINTERRUPTED, SECURE, OR ERROR-FREE, THAT ERRORS OR DEFECTS IN THE SERVICE WILL BE CORRECTED, OR THAT THE SERVICE IS FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY US OR OUR AUTHORIZED REPRESENTATIVES WILL CREATE A WARRANTY. SHOULD THE SERVICE PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION, AND YOU ARE SOLELY RESPONSIBLE FOR ANY LOSS OR CORRUPTION OF DATA THAT RESULTS FROM THE USE OF AND ACCESS TO THE SERVICE. </strong></p>
        <p><strong>SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</strong></p>
        <ol start='13' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
              Indemnification
              </strong>
            </p>
          </li>
        </ol>
        <p>You will defend, indemnify and hold us, our affiliates, parent companies, officers, agents, employees, partners, licensors, contractors, permitted successors and permitted assigns (each of the foregoing, an “<strong>Indemnitee</strong>”) harmless from all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including, but not limited to, professional fees and reasonable attorneys’ fees incurred by one or more Indemnitees, to the extent directly or indirectly arising out of or resulting from: (a) your User-Generated Content; (b) any breach of these Terms by you or any person accessing the Service using your account or device; (c) your access or use of the Service; (d) your violation of any applicable law or regulation; (e) your violation of the rights of any third party, including, but not limited to, privacy, intellectual property or proprietary rights with respect to your use of the Service, performance of your obligations under these Terms, or exercise of rights granted to you under these Terms; and (f) any and all claims for property damage, personal injury or bodily injury or death, to the extent caused by your breach of these Terms. You agree that we will have the right to control the defense, negotiation, and settlement of any claim subject to indemnification by you and that you will fully cooperate with us in the defense, negotiation, or settlement of any such claim, and that we will have the right to select counsel handling such defense, negotiation or settlement in our sole discretion.</p>
        <ol start='14' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Limitation of Liability 
              </strong>
            </p>
          </li>
        </ol>
        <p><strong>TO MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, WE (AND OUR AFFILIATES, PARENT COMPANIES, OFFICERS, AGENTS, EMPLOYEES, PARTNERS, LICENSORS, CONTRACTORS, PERMITTED SUCCESSORS AND PERMITTED ASSIGNS) WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, MORAL, EXEMPLARY, PUNITIVE, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICE, OR ANY USER-GENERATED CONTENT, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE), OR WHETHER SUCH DAMAGE WAS FORESEEABLE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW) EXCEED THE AMOUNT OF FIFTY DOLLARS ($50).  </strong></p>
        <p><strong>THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF CERTAIN TYPES OF DAMAGES, IN WHICH CASE SOME OF THE LIMITATIONS ABOVE MAY NOT APPLY TO YOU. THE ABOVE LIMITATIONS OR EXCLUSIONS DO NOT AFFECT YOUR STATUTORY CONSUMER RIGHTS IN YOUR APPLICABLE JURISDICTION.</strong></p>
        <ol start='15' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Service or Account Suspension
              </strong>
            </p>
          </li>
        </ol>
        <p>We may suspend or terminate your access to your account (if any) and any portion or all of the Service for any reason, including if, in our sole judgment, we determine: (a) there is a threat or attack on any of our services or systems, or any data stored thereon; (b) your use of the Service disrupts or poses a security risk to us or any of our customers or vendors; (c) you are using the Service for purposes of engaging in, or your account is being used to engage in, fraudulent or illegal activities; (d) our provision of the Service to you is prohibited by applicable law or regulation; (e) you fail to comply with one or more provisions of these Terms, or we suspect that you are in violation of, or reasonably likely to be in violation of, one or more provisions of these Terms; or (f) any of our licensors or vendors restricts, suspends, or terminates our access to one or more Third-Party Services or Third-Party Materials that are required for the provision of or access to the Service. We will use reasonable efforts to provide notice of any suspension of the Service to you and to provide updates regarding resumption of access to the Service following any suspension. We will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences resulting from a Service suspension.</p>
        <ol start='16' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Termination
              </strong>
            </p>
          </li>
        </ol>
        <p>These Terms are effective until you perform each of the following: (i) stop using our Service, (ii) uninstall and delete any copies in your possession, (iii) cancel any account, and (iv) cancel all active subscriptions. You may stop using the Service at any time. Please refer to section 5 (<strong>Purchases, Subscriptions, Promotional Codes, and Auto Renewals</strong>) for information on how you can cancel your subscription or any automatic renewals.</p>
        <p>We may terminate these Terms at any time and for any reason by providing you with notice. Your rights under these Terms will terminate automatically if you fail to comply with any of its provisions.</p>
        <p>Upon expiration, termination, or cancellation of these Terms for any reason, (a) you must cease all use of the Service and uninstall and delete all copies of all Apps or software included with the Service in your possession, and (b) all rights and obligations of both parties to these Terms, including all licenses granted under these Terms, will immediately terminate. The provisions of these Terms that are by their nature intended to survive termination or expiration of these Terms will so survive.</p>
        <ol start='17' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Account
              </strong>
            </p>
          </li>
        </ol>
        <p>If the Service allows you or requires you to provide registration information and create an account, these Terms also govern your account. You state that you will provide only true, current and accurate information when you create your account or provide us with the required information, and that you meet the eligibility requirements under these Terms. You agree to update your registration information to keep it accurate and current.</p>
        <p>We may ask you to confirm your registration information (such as your email address) to continue using the Service. If you choose not to do so, your access to the Service may be restricted or terminated.</p>
        <p>When you choose a username or otherwise create a nickname, you agree not to use any name that is unlawful, fraudulent, deceptive, harmful, defamatory, inaccurate, abusive, offensive, threatening, hateful, violent, harassing, discriminatory or racist, or any name that infringes or violates another person’s rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity). You agree not to impersonate any person or misrepresent your identity or affiliation with any person. You further agree not to purchase, sell, rent or give away your account.</p>
        <p>You will not share your account or your registration information, and you will not let anyone else access your account. In the event you become aware of or reasonably suspect any breach of security, including any loss, theft, or unauthorized disclosure of your registration information, you must immediately notify us and modify your registration information. You are solely responsible for maintaining the confidentiality of your registration information, and you will be responsible for all uses of your registration information, including purchases, whether or not authorized by you.</p>
        <p>To the maximum extent permitted under applicable law, you are responsible for anything that happens through your account.</p>
        <ol start='18' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Governing Law 
              </strong>
            </p>
          </li>
        </ol>
        <p>These Terms, and any dispute, claim (including non-contractual disputes or claims), or matters arising out of or in connection with these Terms will be governed by, and construed in accordance with, the substantive laws of the State of Florida, U.S., excluding any conflict-of-laws rule or principle that might refer the governance or the construction of these Terms to the law of any other jurisdiction.</p>
        <ol start='19' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                General
              </strong>
            </p>
          </li>
        </ol>
        <p>Unless otherwise stated in these Terms, we may issue any notice, we may issue any notice that is required or referenced in these Terms to you by (a) email, (b) posting notice through the Service, or (c) other legally accepted means. It is your responsibility to keep your information current to receive notifications and to check the Service regularly (for example, by opening the App once a month). Notices sent by email will be effective when we send the email to the address you provided to us; and notices we provide by posting will be effective upon posting. To give us notice under these Terms, you must contact us as follows: (1) by email to aicreativity@legalmail.it, or (2) by personal delivery, overnight courier or registered or certified mail to Corso Como 15, 20154 Milan, Italy, Attention: Legal. Notices provided by personal delivery will be effective immediately. Notices provided to us by email or overnight courier will be effective one business day after they are sent. Notices provided to us by registered or certified mail will be effective three business days after they are sent.</p>
        <p>You will not assign, subcontract, delegate, or otherwise transfer these Terms, or your rights and obligations hereunder, without obtaining the prior written consent of one of our authorized representatives, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be void. We may assign these Terms or delegate our obligations hereunder, in whole or in part, without your consent, to any person or entity at any time.</p>
        <p>Any failure by us to exercise or enforce any of our rights under these Terms does not waive our right to enforce such right. Any waiver of such rights will only be effective if it is in writing and signed by one of our authorized representatives. The rights and remedies under these Terms are cumulative and are in addition to and not in substitution for any other rights and remedies available at law, in equity or otherwise.</p>
        <p>If any provision or part of these Terms is found to be unlawful, void or for any reason unenforceable, that provision, or the affected part thereof, will be deemed amended so that it is valid and enforceable to the maximum extent permitted by law. Any invalid or unenforceable portion should be construed as narrowly as possible in order to give effect to as much of these Terms as possible. Any unaffected provision of these Terms will remain in full force and effect.</p>
        <p>These Terms will be binding upon the parties and their respective successors and permitted assigns.</p>
        <p>Except as expressly provided in sections 7 (<strong>Our Intellectual Property Rights</strong>), 11 (<strong>Third-Party Services, and Materials and Advertising</strong>), 13 (<strong>Indemnification</strong>), 19 (<strong>General</strong>), 20 (<strong>Additional Terms for App Marketplaces</strong>), and 22 (<strong>Additional Terms for Shutterstock Content</strong>) there are no third-party beneficiaries to these Terms other than each of our affiliates.</p>
        <p>Any delay in the performance of any duties or obligations of either party (except the payment of money owed) will not be considered a breach of these Terms to the extent such delay is caused by a labor dispute, shortage of materials, fire, earthquake, epidemic, flood, act of terrorism, or any other event beyond the control of such party.</p>
        <ol start='20' type='1'>
          <li>
            <p
              style={{
                fontSize: 22,
                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
              }}>
              <strong>
                Additional Terms for App Marketplaces
              </strong>
            </p>
          </li>
        </ol>
        <p>When you access, purchase, or download an App from the Apple App Store, you acknowledge and agree that:</p>
        <ul>
          <li><p>These Terms are concluded between you and Prism, and not with Apple Inc. or its subsidiaries (collectively, “Apple”), and as between Prism and Apple, Prism is solely responsible for the App and the content thereof.</p></li>
          <li><p>You may only use the App on an Apple-branded product that you own or control and as permitted by the Usage Rules set forth in the terms and guidelines that govern your use of the Apple App Store (including the Apple Media Services Terms and Conditions), except that the App may be accessed and used by other accounts associated with your account from which you made your purchase via Family Sharing or volume purchasing.</p></li>
          <li><p>Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App.</p></li>
          <li><p>In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App to you where applicable. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Prism.</p></li>
          <li><p>Apple is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including, but not limited to: (a) product liability claims; (b) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection, privacy, or similar legislation.</p></li>
          <li><p>In the event of any third-party claim that the App or your possession and use of the App infringes that third party’s intellectual property rights, as between Apple and Prism, Prism will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</p></li>
          <li><p>You may not use or otherwise export or re-export the App or any component or product thereof except as authorized by United States law and the laws of the jurisdiction in which the App was obtained. You also agree that you will not use the App or any component or product thereof for any purposes prohibited by such laws. In particular, but without limitation, the App may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the App, you represent and warrant that you are not located in any such country or on any such list.</p></li>
          <li><p>You must comply with applicable third-party terms of agreement when using the App.</p></li>
          <li><p>Apple is a third-party beneficiary of these Terms, and Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary thereof.</p></li>
        </ul>
       <p>When you access, purchase, or download the App from Google Play, you acknowledge and agree that:</p>
       <ul>
          <li><p>To the extent that these Terms conflict with the Google Play Developer Distribution Agreement, the Google Play Terms of Service or the terms and guidelines that govern your use of Google Play (the “<strong>Google Play Terms</strong>”), the Google Play Terms will prevail with respect to your use of the App that you access, purchase or download from Google Play.</p></li>
          <li><p>Google LLC or its subsidiaries will not be responsible for and will not have any liability under these Terms.</p></li>
        </ul>
        <p><strong>Last updated:</strong> 9 May 2024</p>
      </div>
    </Content>
  )
}

const Content = styled.main`
  display: block;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
