import React from "react";
import styled from "styled-components"

export default function Privacy() {
    return (
        <Content className="container updated-privacy-policy">
            <div className="wrapper" style={{maxWidth:892,margin:'0 auto'}}>
                <div>
                    <IntroSection className="intro-section">
                        <div>
                            <h2 className="slate-h2">
                                <strong>
                                     How Prism protects your images and other data
                                </strong>
                            </h2>
                            <div className="slate-p"> 
                            We want to help you understand how Prism processes your personal data, as well your options when it comes to managing it. It’s important that you also understand how Prism protects your images and videos.
                            </div>
                            <div className="slate-p"> 
                                You’ll find all this information in our privacy policy, which is below.
                            </div>
                            <div className="slate-p"> 
                                The following bullets highlight a few key details about the app and our security measures:
                            </div>
                            <ul>
                                <li>
                                    <p>Prism is a photo- and video-editing app. It enhances photos and videos by improving aspects such as image resolution and colors. Also, it gives you the ability to generate new images with AI.</p>
                                </li>
                                <li><p>Prism'stechnologies are not used to identify or authenticate people in the images or videos.</p></li>
                                <li><p>You retain control over the images and videos you upload, enhance, and generate using Prism. We don’t sell, trade, or claim ownership of any of your images or videos—no matter whether you use Prism to edit your existing images or videos or to generate new ones.</p></li>
                                <li><p>Your images and videos are safeguarded with appropriate security measures, following privacy and data protection laws and industry standards. This includes encryption and cloud storage for enhanced security.</p></li>
                            </ul>
                            <div className="slate-p"> 
                                To know how we process your personal data when using Remini, please refer to the privacy policy.
                            </div>
                            <h2 className="slate-h2">
                                <strong>
                                    Privacy policy
                                </strong>
                            </h2>
                            <div className="slate-p"> 
                                We’re the Chengdu Ziqi Technology Co. LTD. (“we” or “us”), With this privacy policy, we’ll explain how we process the personal data of users (“user” or “you”) of the Prism mobile application (“app”), or those who otherwise interact with us. 
                            </div>
                            <div className="slate-p"> 
                                We provide this privacy policy in accordance with Regulation (EU) 2016/679–General Data Protection Regulation (“GDPR”), the Italian Legislative Decree 196/2003 (as amended), and other applicable local laws, as amended or replaced (collectively, “applicable privacy laws”). If you’re using an app version older than iOS 14, you should refer to this version of the privacy policy.
                            </div>
                            <p><strong>Last updated:</strong> 9 May, 2024</p>
                        </div>
                    </IntroSection>
                    <div className='content-wrapper'>
                    <ol start='1' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Recipients of your data </strong>
                            </p>
                        </li>
                    </ol>
                    <p>Your personal data may be transmitted to trusted and reliable third parties. This happens only when there are lawful grounds for the transmission. We don’t sell your data, including your images, to third parties under any circumstances.</p>
                    <p>The types of third parties to which your data may be transmitted are as follows:</p>
                    <ul>
                        <li><p>Third parties that carry out activities related to our business and operations. Such parties can operate either on our behalf as data processors (for example, IT or storage service providers) or as autonomous data controllers (for example, professional advisors providing advice or otherwise protecting and managing our business interests).</p></li>
                        <li>
                            <p>
                            Third parties that collect personal data about you to show personalized advertising (if you consent to the installing of tracking technologies). To learn more about their activities, see the third parties’ privacy policies linked in the section <i>In-app tracking technologies</i>. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Advisors and new owners, in the event that we carry out a corporate transaction or operation such as a merger, acquisition, reorganization, sale of assets or assignments, and as a part of the associated due diligence.
                            </p>
                        </li>
                        <li>
                            <p>Public, judicial, or police authorities, within the limits established by applicable laws.</p>
                        </li>
                        <li>
                            <p>Other parties as necessary (for example, competent authorities), if we have reason to believe that your actions are inconsistent with our user agreements or policies or that you’ve violated the law, or if it’s necessary to protect our rights, property, and safety or that of our users, the public, or others.</p>
                        </li>
                    </ul>
                    <p>Additionally, we may share your personal data if it’s required for the fulfillment of a legal obligation, or if you give us your consent to do so.</p>
                    <ol start='2' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Children’s data</strong>
                            </p>
                        </li>
                    </ol>
                    <p>The app isn’t meant to be used by people under the age of 16. We don’t knowingly collect personal data from children. If you believe that we’ve received personal data from children under the age of 16, please email us at privacy@bendingspoons.com. If we learn that a user is under the age of 16, we’ll take reasonable steps to delete any processed data and close the associated account.</p>
                    <ol start='3' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Third-party websites and services </strong>
                            </p>
                        </li>
                    </ol>
                    <p>The app may include links to other websites or services operated by third parties. The activities described in this privacy policy don’t apply to data processed by third-party websites and services. We’ve got no control over, and we're not responsible for, the actions and privacy policies of third parties and other websites and services.</p>
                    <ol start='4' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Changes to this privacy policy </strong>
                            </p>
                        </li>
                    </ol>
                    <p>We may modify, integrate, or update—in whole or in part—this privacy policy. We’ll notify users of any modification, integration, or update in accordance with applicable privacy laws.</p>
                    <p>If we make modifications, we’ll notify you by revising the date at the bottom of this privacy policy and, under certain circumstances, we may also notify you by additional means (such as via email, pop-up, or push notification).</p>
                    <ol start='5' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Types of data, how data is used, and retention: Activities that we perform only in extraordinary circumstances </strong>
                            </p>
                        </li>
                    </ol>
                    <p>Although it doesn’t happen by default, your personal data may be processed in certain extraordinary circumstances that aren’t related to the functioning of the product. These extraordinary circumstances are as follows:</p>
                    <p><strong>a) Extraordinary circumstances: For compliance with legal obligations</strong></p>
                    <p>Your personal data may be used to comply with our legal obligations, including requests from public authorities, and to prove that we have complied with them, such as in the event of a request from a public authority.</p>
                    <p>
                        <strong>Categories of data.</strong> We may process any kind of information that may be required by law or under the instructions of public authorities.
                    </p>
                    <p><strong>Legal basis.</strong> When this activity is required by a specific legal obligation, your personal data may be used to the extent required to comply with the legal obligation itself (Article 6.1.c GDPR). When the applicable law leaves some discretion in assessing the appropriate way to comply with it, your personal data is used based on our legitimate interest to prove our compliance (Article 6.1.f GDPR).</p>
                    <p>
                    <strong>Storage.</strong> We retain personal data used for this purpose for a maximum of five (5) years from your most recent interaction with the app, or from the expiration of your subscription. If you access the app after your subscription has expired, the retention period starts from this most recent interaction. Specific legal obligations might require different retention periods and, in such cases, those different periods will apply. Upon the expiry of the retention period, the data is either deleted or anonymized.
                    </p>
                    <p><strong>b) Extraordinary circumstances: For defense</strong></p>
                    <p>Your personal data may be used to establish, exercise, or defend our rights and those of our employees, and to carry out corporate transactions or operations. For example, your data may be processed in the event of bankruptcy, merger, acquisition, reorganization, and sale of assets or assignments, and the due diligence related to any such transactions.</p>
                    <p><strong>Categories of data.</strong> We may process any kind of information necessary to ensure the correct execution of the activity.</p>
                    <p><strong>Legal basis.</strong> This activity is based on our legitimate interest to establish, exercise, or defend our rights, and to carry out corporate transactions or operations (Article 6.1.f GDPR).</p>
                    <p><strong>Storage. </strong>We retain personal data used for this purpose for a maximum of ten (10) years from your most recent interaction with the app, or from the expiration of your subscription. If you access the app after your subscription has expired, the retention period starts from this most recent interaction. Specific legal obligations might require different retention periods and, in such cases, those different periods will apply. Upon the expiry of the retention period, the data is either deleted or anonymized.</p>
                    <ol start='6' type='1'>
                        <li>
                            <p
                            style={{
                                fontSize: 22,
                                color: ' rgba(0, 0, 0, var(--tw-teChaxt-opacity))'
                            }}>
                            <strong>Your Rights </strong>
                            </p>
                        </li>
                    </ol>
                    <p>You can always retain control of your personal data. Depending on your location, you have certain rights in relation to your data that you can exercise at any time and without charge, in accordance with the limitations and exceptions stated by the applicable privacy laws. These include the following:</p>
                    <ul>
                        <li><p>Right to access and receive a copy of information about the processing of your personal data (access).</p></li>
                        <li><p>Right to request that your personal data be updated, rectified, or integrated (rectification).</p></li>
                        <li><p>Right to request that your personal data be deleted (erasure).</p></li>
                        <li><p>Right to request that processing of your personal data be restricted (restriction of processing).</p></li>
                        <li><p>Right to receive a portable electronic copy of your personal data (data portability).</p></li>
                        <li><p>Where we rely on our legitimate interest to process your personal data, you have the right to object to such processing in whole or in part (objection). In particular, you have the right to object to the processing of your personal data for direct marketing, including profiling.</p></li>
                        <li><p>Where we rely on your consent to process your personal data, you have the right to withdraw your consent (consent withdrawal). If you do so, the processing carried out before your withdrawal will remain valid, but thereafter we’ll no longer process your data for that purpose.</p></li>
                    </ul>
                    <p>You also have the right to complain before the competent national data protection authority, or any other applicable regulator in the jurisdiction where you reside, in the event that you think we’ve violated the applicable privacy laws and we haven’t addressed your request.</p>
                    </div>
                </div>
            </div>
        </Content>
    )
}

const Content = styled.div`
  display: flex;
  
`
const IntroSection = styled.div`
    margin-bottom: 0px;
    padding-bottom: 3rem;
    text-align: left;
`
